@import "_functions.scss";
@import "_variables.scss";
@import "_mixin.scss";

input, button, select, textarea {
	font-family: "Sage Text", "Helvetica Neue", Roboto, "Segoe UI", Arial;	
}

.page-header .lead {
	font-weight: 400;
}

prm-shared-field-wrapper [prm-field-editor] .prefix-label {
	display: none;
}

w-impartner-company-header-view .input-element-container.big {
	margin-bottom: 0;
}

w-impartner-user-header-view .input-element-container.big,
w-impartner-user-header-view .input-element-container.normal,
w-impartner-user-header-view .single-dropdown-element-container {
	margin-bottom: 0 !important;
}

w-impartner-user-header-view .input-element-container:not(.big, .normal) .field.field--preview,
w-impartner-user-header-view .single-dropdown-element-container .field.field--preview {
	font-size: 14px !important;
}

w-impartner-user-header-view .input-element-container.small {
	margin-bottom: 0 !important;
	margin-top: 16px;
}

w-impartner-user-header-view .input-element-container.small .field.field--preview {
	padding: 0 !important;
}

w-impartner-company-header-view .right-column {
	align-items: center !important;
}

w-impartner-company-header-view .right-column .button-container {
	margin-left: 5px;
}

prm-shared-field-wrapper [prm-field-editor] .form-control {
	padding: 22px 16px 4px 16px;
}

prm-shared-field-wrapper [prm-field-editor] .element-default-mode .form-control {
	border-radius: 4px;
}

prm-shared-field-wrapper [prm-field-editor] .element-readonly {
	background-color: transparent;
}

[prm-field-editor] .element-default-mode.element-readonly .form-control,
[prm-field-editor] .element-readonly input, 
[prm-field-editor] .element-readonly .text-area {
	border: 1px solid #CCD6DB;
	background-color: #F2F5F6;
	color: rgba(0,0,0,.9);
}

prm-shared-field-wrapper[shared-field-wrapper] .floating-label-wrap .floating-label-field + .floating-label {
	font-size: 16px;
	font-weight: 500;
	left: 16px;
	top: 15px;
	color: rgba(0,0,0,.65);
}

prm-shared-field-wrapper[shared-field-wrapper] .floating-label-active {
	-webkit-transform: translate(0%, -42%) scale(0.75);
	transform: translate(1px, -42%) scale(0.75);
}

prm-shared-field-wrapper [prm-field-editor] .floating-label-field .selectize-input {
	font-size: 16px;
	padding: 27px 0 0 16px;
}

prm-shared-field-wrapper .selectize-dropdown, prm-shared-field-wrapper .selectize-input, prm-shared-field-wrapper .selectize-input input {
	color: rgba(0,0,0,.9);
}

[prm-field-editor] .selectize-control.multi .selectize-input.has-items {
	padding: 7px 8px 6px 10px;
}

[site-footer] .bottom-menu ul > li {
	margin: 8px 16px;
}

[site-footer] .bottom-menu ul > li a:hover {
	color: #00D639;
	text-decoration: underline;
}

[top-bar].top-bar .form-control.prm-searchbar-input {
	padding: 0 16px;
	height: 32px;
	background-color: #333333;
}

[top-bar].top-bar .btn-search {
	height: 32px;
	background-color: #333333;
}

[top-bar].top-bar .btn-search:hover {
	background-color: #444444;
}

[top-bar].top-bar .btn-search::-ms-input-placeholder,
[top-bar].top-bar .btn-search::placeholder {
	color: rgba(0,0,0,.65);
}

[top-bar].top-bar .top-search-bar .input-group.prm-searchbar-group .prm-searchbar-button .glyphicon {
	top: 3px;
}

[top-bar] .widget-item .dropdown .dropdown-menu {
	padding: 8px 0;
}

[top-bar] [user-account].widget-dropdown .dropdown {
	margin: 0;
}

[top-bar] .widget-item .dropdown-menu > li > a {
	padding: 10px 24px;
}

[top-bar] .widget-item ul.widget-dropdown li i {
	color: #FFFFFF;
	background-color: transparent;
}

[top-bar] .widget-item ul.widget-dropdown li a:hover i {
	color: #00D639;
}

[top-bar] .widget-item .fa-user {
	font-size: 16px;
}

[top-bar] [push-notifications] .badge {
	background-color: transparent;
	position: relative;
}

[top-bar] .widget-item [push-notifications] .badge .fas {
	font-size: 16px;
	color: #FFFFFF;
}

[top-bar] .widget-item [push-notifications]:hover .badge .fas,
[top-bar] .widget-item .dropdown:hover [push-notifications] .badge .fas {
	color: #00D639;
	background-color: transparent;
}

[top-bar] .widget-item [push-notifications] .badge .prm-notify-unreadcount {
	background-color: #FFB500;
	font-size: 11px;
	font-weight: bold;
	color: #000;
	padding: 1px 4px 2px;
	border-radius: 16px;
	position: absolute;
	top: -2px;
	right: -4px;
	min-width: 16px;
	text-align: center;
	height: 16px;
}

[top-bar] .widget-item [push-notifications] .badge .prm-notify-unreadcount:empty {
	display: none;
}

[top-bar] .widget-item .dropdown .dropdown-menu::before {
	content: "";
	display: block;
	top: -16px;
	right: 22px;
	border: #ffffff 8px solid;
	border-top-color: transparent;
	border-right-color: transparent;
	border-left-color: transparent;
	left: auto;
	width: auto;
	height: auto;
}

[top-bar] .widget-item .dropdown [user-account].dropdown-menu::before {
	right: 16px;
}

[top-bar] [push-notifications] .prm-notify-menu-message .prm-icon {
	display: none;
}

[push-notifications] .prm-notify-menu-message > a h4 {
	font-family: "Sage Text", "Helvetica Neue", Roboto, "Segoe UI", Arial;
	margin-bottom: 0;
}

[push-notifications] .prm-notify-menu-message > a span, 
[push-notifications] .prm-notify-menu-message > a span.text-muted {
	color: rgba(0,0,0,.65);
	font-size: 14px;
}

[top-bar] .widget-item .dropdown-menu > li > a:hover span.text-muted, 
[top-bar] .widget-item .dropdown-menu > li > a:focus span.text-muted {
	color: rgba(255,255,255,.65);
}


[push-notifications] .prm-notify-menu-message {
	border-bottom: none;
}

[push-notifications] .push-btn-group {
	text-align: left;
	padding: 16px 24px;
}

@media only screen and (min-width: 1200px) {
	[top-nav] .navbar-nav > li > a {
		font-size: 16px;
		padding-left: 16px;
		padding-right: 16px;
	}
	
	[top-nav] .navbar-right .dropdown-toggle .caret {
		display: none;
	}
	
	[top-nav] .dropdown-menu {
		box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.2);
	}
}

[top-nav] .navbar-right > .dropdown .dropdown-menu {
	padding: 8px 0;
	overflow: visible;
}

[top-nav] .navbar-right > .dropdown .dropdown-menu::before {
	top: -18px;
	left: auto;
	right: 31px;
}

@media only screen and (max-width: 1199px) {
	[top-nav] .navbar-brand {
		padding-top: 8px;
	}
	
	[top-nav] .navbar-toggle {
		margin-top: 10px;
	}
	
	[top-nav] #navbarCollapse.in {
		background-color: #000000;
		max-height: unset;
	}
	
	[top-nav] .caret {
		filter: invert(1);
		scale: 80%;
	}
	
	[top-nav].navbar-default .navbar-nav > li {
		margin-top: 0;
		margin-bottom: 0;
	}
	
	[top-nav].navbar-default .navbar-nav > li, 
	[top-nav].navbar-default .navbar-nav > li > a, 
	[top-nav].navbar-default .navbar-nav > li > a:hover, 
	[top-nav].navbar-default .navbar-nav > li > a:focus {
		background-color: #000000;
	}
	
	[top-nav] .navbar-right > .dropdown .dropdown-menu::before {
		content: none;
	}
	
	[top-nav].navbar-default .navbar-nav > .open:hover > a,
	[top-nav].navbar-default .navbar-nav > .open:hover > a span,
	[top-nav].navbar-default .navbar-nav > .open:hover > a i,
	[top-nav].navbar-default .navbar-nav > .open > a span,
	[top-nav].navbar-default .navbar-nav > .open > a:hover span,
	[top-nav].navbar-default .navbar-nav > .open > a:focus span,
	[top-nav].navbar-default .navbar-nav > .open > a,
	[top-nav].navbar-default .navbar-nav > .open > a:hover,
	[top-nav].navbar-default .navbar-nav > .open > a:focus {
		background-color: #1A1A1A;
	}
	
	[top-nav].navbar-default .navbar-nav > .open > a .caret,
	[top-nav].navbar-default .navbar-nav > .open > a:hover .caret,
	[top-nav].navbar-default .navbar-nav > .open > a:focus .caret {
		background-color: transparent;
	}
	
	[top-nav].navbar-default .navbar-nav .open .dropdown-menu > li > a {
		background-color: #1A1A1A;
	}
	
	[top-nav] .navbar-right > .dropdown .dropdown-menu {
		padding-top: 0;
	}
}

w-impartner-dynamic-form-content [df-content] {
	margin-top: 0;
}

prm-df-content-control-buttons [df-content-control-buttons] .form-action-buttons {
	margin: 40px 0;
}

[site-footer] .footer-social .icon-sm {
	font-size: 18px;
	padding: 0 16px;
	margin: 0;
}

[legacy-html-view] h1 {
	margin-top: 64px;
}

[legacy-html-view] .page-header h1 {
	margin-top: 24px;
}

[applicant-lookup] [al-accounts-list] .account-list .radio-option {
	display: grid;
}

[applicant-lookup] [al-accounts-list] .account-list .radio-label {
	text-align: left;
}